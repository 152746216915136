import PromoImages from "./PromoImages";
import img1 from "../assets/images/promos/trad_fest/promo (1).jpg";
import img2 from "../assets/images/promos/trad_fest/promo (2).jpg";
import img3 from "../assets/images/promos/trad_fest/promo (3).jpg";
import img4 from "../assets/images/promos/trad_fest/promo (4).jpg";
import img5 from "../assets/images/promos/trad_fest/promo (5).jpg";
import img6 from "../assets/images/promos/trad_fest/promo (6).jpg";
import img7 from "../assets/images/promos/trad_fest/promo (7).jpg";
import img8 from "../assets/images/promos/trad_fest/promo (8).jpg";
import img9 from "../assets/images/promos/trad_fest/promo (9).jpg";
import img10 from "../assets/images/promos/trad_fest/promo (10).jpg";
import img11 from "../assets/images/promos/trad_fest/promo (11).jpg";
import img12 from "../assets/images/promos/trad_fest/promo (12).jpg";
import img13 from "../assets/images/promos/trad_fest/promo (13).jpg";
import img14 from "../assets/images/promos/trad_fest/promo (14).jpg";
import img15 from "../assets/images/promos/trad_fest/promo (15).jpg";
import img16 from "../assets/images/promos/trad_fest/promo (16).jpg";
import img17 from "../assets/images/promos/trad_fest/promo (17).jpg";
import flyer from "../assets/images/promos/trad_fest/flyer.jpg";

export default function TradFest() {
  const images = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
  ];
  const name = "trad";

  return (
    <>
      <div className="row justify-content-center text-center mt-5 mb-5">
        <img src={flyer} alt="promo flyer" style={{ maxWidth: "800px" }} />
        {/* <h3 className="mt-5">Details:</h3>
        <ul style={{ listStyleType: "none" }} className="fs-5 mb-5">
          <li>
            Price ranges: $100, $150, $200 (based off size, placement,
            outline/shading/color, etc)
          </li>
        </ul> */}
      </div>
      <PromoImages images={images} name={name} />
    </>
  );
}
