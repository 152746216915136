import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Terms = () => {
  return (
    <>
      <Helmet>
        <title>Sacred Art Tattoo Hawaii | Terms of Service</title>
      </Helmet>
      <div className="container mt-60 mb-60 text-start">
        <h1 className="text-center mb-5">
          Terms of Service for SMS Communication
        </h1>
        <p className="fw-bold fs-5 mb-2">Last Updated:12/01/24</p>
        <p className="mb-4">
          By opting into SMS communications with Sacred Art Tattoo Hawaii (“we,”
          “us,” “our”), you agree to the following terms and conditions.
        </p>
        <p className="fw-bold fs-5 mb-2">1. Consent for SMS Communication</p>
        <p className="mb-4">
          By providing your phone number and opting in to receive SMS messages,
          you consent to receive text messages related to your interactions with
          Sacred Art Tattoo Hawaii. This may include booking inquiries,
          appointment updates, and feedback requests.
          <br />
          <br />
          We respect your privacy. The information obtained as part of the SMS
          consent process will not be shared with third parties.
        </p>
        <p className="fw-bold fs-5 mb-2">2. Types of SMS Communications</p>
        <p className="mb-1">
          You may receive SMS messages for the following purposes:
        </p>
        <ul className="mb-4">
          <li>
            <strong>Customers and Guests: </strong>Appointment reminders,
            confirmations, feedback requests, and other updates related to
            services.
          </li>
        </ul>
        <p className="fw-bold fs-5 mb-2">3. Messaging Frequency and Costs</p>

        <ul className="mb-4">
          <li>
            The frequency of messages may vary depending on your interaction
            with us.
          </li>
          <li>
            Standard messaging and data rates may apply. Please contact your
            mobile provider for specific details about fees.
          </li>
        </ul>
        <p className="fw-bold fs-5 mb-2">4. Opting In</p>
        <p className="mb-1">
          When opting into SMS communication, you will receive the following
          confirmation message:
        </p>
        <p className="mb-4 ms-5">
          “Thank you for opting into SMS messages from Sacred Art Tattoo Hawaii
          To opt out at any time, reply STOP. For assistance, reply HELP.
          Message and data rates may apply. Messaging frequency may vary.”
        </p>
        <p className="fw-bold fs-5 mb-2">5. Opting Out</p>
        <p className="mb-1">
          You can opt out of receiving SMS messages at any time by replying STOP
          to any message. You will receive the following confirmation:
        </p>
        <p className="mb-4 ms-5">
          “Thank you for opting out of SMS messages from Sacred Art Tattoo
          Hawaii. To opt back in, reply START. You will no longer receive SMS
          communications.”
        </p>
        <p className="fw-bold fs-5 mb-2">6. Help and Support</p>
        <p className="mb-1">
          For assistance, reply HELP to any message. You will receive the
          following response:
        </p>
        <p className="mb-4 ms-5">
          “Thank you for contacting Sacred Art Tattoo Hawaii. For immediate
          assistance, contact us at 1-808-892-3335.”
        </p>
        <p className="fw-bold fs-5 mb-2">7. Limitations and Liability</p>
        <ul className="mb-4">
          <li>
            SMS communication is provided as a convenience and may be subject to
            delays, interruptions, or failures due to technical or network
            issues.
          </li>
          <li>
            Sacred Art Tattoo Hawaii is not responsible for any delays or errors
            in SMS delivery.
          </li>
        </ul>
        <p className="fw-bold fs-5 mb-2">8. Privacy Policy</p>
        <p className="mb-4">
          Your privacy is important to us. Please review our{" "}
          <Link to="/privacy" className="text-decoration-underline">
            Privacy Policy
          </Link>{" "}
          for details on how we collect, use, and protect your information.
        </p>
        <p className="mb-4">
          If you have any questions about these terms, please contact us at:
          <br />
          <strong>Email: </strong>
          <a href="mailto:sales@sacredarthawaii.com">
            sales@sacredarthawaii.com
          </a>
          <br />
          <strong>Phone: </strong>
          <a href="tel:8088923335">808-892-3335</a>
        </p>
      </div>
    </>
  );
};

export default Terms;
